import putApi from "apis/put.api";
import { handleError, handleSuccess } from "helpers/commonFunctions";
import { useState } from "react";
import { ButtonGroup, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row, Spinner, UncontrolledButtonDropdown } from "reactstrap"
import Select from "react-select";

const Edit = ({defaultSettle,id,merchantInformation,setMerchantInformation,defaultCountry,countries} : any) => {
    const [isLoading, setIsLoading] = useState(false);

    const settle = [
        {
            label: 'Yes',
            value: '1',
        },
        {
            label: 'No',
            value: '0',
        }
    ];

    const changeCountry = (element: any) => {
        setMerchantInformation({
            ...merchantInformation,
            ['merchantCountryId']: element.value,
        });
    };
    
    const changeAutoSettle = (element: any) => {
        setMerchantInformation({
            ...merchantInformation,
            ['merchantAutoSettleNoneClvrPay']: element.value,
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMerchantInformation({
            ...merchantInformation,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdate = () => {
        setIsLoading(true); 
        putApi(`/admin/merchant/update/${id}`, merchantInformation).then((response) => {
            handleSuccess(response);
        }).catch((error) => {
            handleError(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const merchantStatusUpdate = (status : string) => {
        putApi(`/admin/merchant/edit/${id}`,{status : status}).then(response => {
            handleSuccess(response)
            window.location.reload()
        }).catch(error => {
            handleError(error)
        })
    }

    const renderDropdownOptions = () => {
        switch (merchantInformation?.merchantStatus) {
            case "a": // Approved
                return (
                    <>
                        <DropdownItem onClick={() => {merchantStatusUpdate('r')}}> Reject </DropdownItem>
                        <DropdownItem onClick={() => {merchantStatusUpdate('b')}}> Block </DropdownItem>
                    </>
                );
            case "b": // Blocked
                return (
                    <>
                        <DropdownItem onClick={() => {merchantStatusUpdate('r')}}> Reject </DropdownItem>
                        <DropdownItem onClick={() => {merchantStatusUpdate('a')}}> Approve </DropdownItem>
                    </>
                );
            case "r": // Rejected
                return (
                    <>
                        <DropdownItem onClick={() => {merchantStatusUpdate('a')}}> Approve </DropdownItem>
                        <DropdownItem onClick={() => {merchantStatusUpdate('b')}}> Block </DropdownItem>
                    </>
                );
            case "p": // Pending
                return (
                    <>
                        <DropdownItem onClick={() => {merchantStatusUpdate('r')}}> Reject </DropdownItem>
                        <DropdownItem onClick={() => {merchantStatusUpdate('b')}}> Block </DropdownItem>
                        <DropdownItem onClick={() => {merchantStatusUpdate('a')}}> Approve </DropdownItem>
                    </>
                );
            default:
                return null;
        }
    };
    
    return <div className="card">
        <div className="card-body">
            <Row className={"gap-3"}>
                <Col md={12} xs={12} lg={4} xl={3}>
                    <Label for="merchantUsername" className="form-label">Merchant Name</Label>
                    <Input
                        type="text"
                        name="merchantUsername"
                        className="form-control"
                        value={merchantInformation.merchantUsername}
                        onChange={handleInputChange}
                        placeholder="Enter merchant name"
                    />
                </Col>
                <Col md={12} xs={12} lg={4} xl={3}>
                    <Label for="merchantEmail" className="form-label">Merchant Email</Label>
                    <Input
                        type="email"
                        name="merchantEmail"
                        className="form-control"
                        value={merchantInformation.merchantEmail}
                        onChange={handleInputChange}
                        placeholder="Enter merchant email"
                    />
                </Col>
                {defaultCountry && <Col md={12} xs={12} lg={4} xl={3}>
                    <Label for="merchantCountryName" className="form-label">Merchant Country</Label>
                    <Select
                        options={countries}
                        id="choices-single-default"
                        className="js-example-basic-single mb-0"
                        name="country_id"
                        defaultValue={defaultCountry}
                        onChange={changeCountry}
                    />
                </Col>}
                <Col md={12} xs={12} lg={4} xl={3}>
                    <Label for="merchantWebsiteUrl" className="form-label">Merchant Website</Label>
                    <Input
                        type="text"
                        name="merchantWebsiteUrl"
                        className="form-control"
                        value={merchantInformation.merchantWebsiteUrl}
                        onChange={handleInputChange}
                        placeholder="Enter website"
                    />
                </Col>
                <Col md={12} xs={12} lg={4} xl={3}>
                    <Label for="merchantPhoneNumber" className="form-label">Merchant Phone Number</Label>
                    <Input
                        type="text"
                        name="merchantPhoneNumber"
                        className="form-control"
                        value={merchantInformation.merchantPhoneNumber}
                        onChange={handleInputChange}
                        placeholder="Enter phone number"
                    />
                </Col>
                <Col md={12} xs={12} lg={4} xl={3}>
                    <Label for="merchantCommission" className="form-label">Merchant Settlement Commission</Label>
                    <Input
                        type="text"
                        name="merchantCommission"
                        className="form-control"
                        value={merchantInformation.merchantCommission}
                        onChange={handleInputChange}
                        placeholder="Enter commission"
                    />
                </Col>
                <Col md={12} xs={12} lg={4} xl={3}>
                    <Label for="merchantPayoutCommission" className="form-label">Merchant Payout Commission</Label>
                    <Input
                        type="text"
                        name="merchantPayoutCommission"
                        className="form-control"
                        value={merchantInformation.merchantPayoutCommission}
                        onChange={handleInputChange}
                        placeholder="Enter payout commission"
                    />
                </Col>
                {defaultSettle && <Col md={12} xs={12} lg={4} xl={3}>
                    <Label for="merchantAutoSettleNoneClvrPay" className="form-label">Merchant Auto ClvrPay Settle</Label>
                    <Select
                        options={settle}
                        id="merchantAutoSettleNoneClvrPay"
                        className="js-example-basic-single mb-0"
                        name="merchantAutoSettleNoneClvrPay"
                        defaultValue={defaultSettle}
                        onChange={changeAutoSettle}
                    />
                </Col>}
                <Col md={12} xs={12} lg={4} xl={3}>
                    <Label for="merchantPayoutCommission" className="form-label">Merchant Wallet Balance</Label>
                    <Input
                        type="text"
                        name="merchantBalance"
                        className="form-control"
                        value={merchantInformation.merchantBalance ?? 0}
                        readOnly={true}
                        disabled={true}
                    />
                </Col>
                <Col md={12} xs={12} lg={4} xl={3}>
                    <ButtonGroup>
                        <UncontrolledButtonDropdown id="btnGroupDrop1">
                            <DropdownToggle color="primary" caret size={"sm"}>
                                Edit Merchant Actions
                            </DropdownToggle>
                            <DropdownMenu>{renderDropdownOptions()}</DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </ButtonGroup>
                </Col>
                
            </Row>
            <div className="hstack gap-2 justify-content-end">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleUpdate}
                    disabled={isLoading} // Disable button while loading
                >
                    {isLoading ? <Spinner size="sm" /> : "Update"} {/* Loader inside button */}
                </button>
            </div>
        </div>
    </div>
}

export default Edit